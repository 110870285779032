import React from "react"

import CharactersTemplate from "../../templates/characters/characters"

import locale from "./index.en.yaml"

const CharactersPage = props => (
  <CharactersTemplate {...props} locale={locale} />
)

export default CharactersPage
